import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import { 
  Box,
  Container, 
  Flex, 
  Heading,
} from "@chakra-ui/react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Providers from "../components/providers"


const PageTemplate = ({ data: { post } }) => {
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Providers>
      <Layout>
        <SEO title={post.title} />
        <article
          className="page-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Container maxW="container.lg">
            <Flex as="header" flexDirection="column" paddingTop="32">
              {/* // </Container>if we have a featured image for this post let's display it
                {featuredImage?.fluid && (
                  <Image
                    fluid={featuredImage.fluid}
                    alt={featuredImage.alt}
                    style={{ marginBottom: 50 }}
                  />
                )} */
              }
              <Heading as="h1" size="2xl" itemProp="headline">{parse(post.title)}</Heading>
              <hr />
            </Flex>
          </Container>
          <Container maxW="container.lg">
            {!!post.content && (
              <Box as="section" className="page-content" itemProp="articleBody">{parse(post.content)}</Box>
            )}          
          </Container>
        </article>
      </Layout>
    </Providers>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

  }
`
